<template>
  <div class="fill-height" style="width: 100%">
    <v-row>
      <v-col>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mb-1"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-checkbox label="selezionare per vedere i film in cinelandia" v-model="cinelandiaFilter"></v-checkbox>
      </v-col>
      <v-col cols="3">
        <v-checkbox label="selezionare per vedere i film successivi a oggi" v-model="todayFilter"></v-checkbox>
      </v-col>
    </v-row>
    <v-data-table
        height="100%"
        widths="100%"
        :loading="loadTable"
        :headers="headers"
        :items="filterFilms"
        :items-per-page="5"
        fixed-header
        class="elevation-1 fill-height "
        style="padding-bottom: 90px"
        :search="search"
    >
      <template v-slot:item.modify="{ item }">
        <v-btn @click="modifyFilmDialog(item)" icon class="primary--text">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn @click="removeFilmDialog(item)" icon class="red--text">
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.image="{ item }">
        <!--        <v-avatar >-->
        <img
            style="border-radius: 2px; max-height: 90px"
            :src="item.image"
        >
        <!--        </v-avatar>-->
      </template>
      <template v-slot:item.today="{ item }">
        <v-icon :class="item.today?'green--text':'red--text'">
          {{ item.today ? 'mdi-check-bold' : 'mdi-close-thick' }}
        </v-icon>
      </template>
      <template v-slot:item.cinelandia="{ item }">
        <v-icon :class="item.cinelandia?'green--text':'red--text'">
          {{ item.cinelandia ? 'mdi-check-bold' : 'mdi-close-thick' }}
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog v-model="openDialogModify" v-if="openDialogModify">
      <v-card>
        <v-card-title>
          Modifica
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-text-field
                v-model="filmToModify.title"
                label="TITOLO"
                @keypress="filmToModify.modify = true"
            />
          </v-row>
          <v-row>
            <v-text-field
                v-model="filmToModify.type"
                label="GENERE"
                @keypress="filmToModify.modify = true"
            />
          </v-row>
          <v-row>
            <v-textarea
                v-model="filmToModify.description"
                @keypress="filmToModify.modify = true"
            />
          </v-row>
          <v-row
              v-for="(d,index) in filmToModify.dates"
              :key="index"
              :class="moment(d.day.toDate()).format('M/D/YYYY') === moment(new Date()).format('M/D/YYYY')?today:''"
          >
            <v-col style="margin-right:10px ">
              {{ getDay(d.day.toDate().getDay()) }}
              {{ d.day.toDate().getDate() }}/{{ d.day.toDate().getMonth() + 1 }}/{{ d.day.toDate().getFullYear() }}
            </v-col>
            <v-col justify="start">
              <v-chip
                  v-for="(hour,index) in d.hours"
                  :key="index"
                  class="ml-5"
                  close
                  @click:close="hour.selected=false ; filmToModify.modify = true"
              >
                          <span v-if="hour.selected">
                          {{ hour.value }}
                          </span>
              </v-chip>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="green" :loading="loadingModify" @click="saveModifyFilm(filmToModify)">modifica film</v-btn>
          <v-btn text color="red" @click="openDialogModify = false">annulla</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="openDeleteFilmDialog"
        max-width="400"
    >
      <v-card>
        <v-card-title>Eliminare il Film</v-card-title>
        <v-card-text>Sei Sicuro di Rimuovere {{ filmToRemove.title }}</v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
              color="error"
              text
              @click="removeFilm(filmToRemove.id)"
          >
            Elimina
            <v-icon dark>
              mdi-delete
            </v-icon>
          </v-btn>

          <v-btn
              color="primary"
              text
              @click="openDeleteFilmDialog= false"
          >
            Annulla
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
        v-model="openSuccessDialog"
        color="success"
    >
      <v-icon dark>
        mdi-chevron-down-circle
      </v-icon>
      Il film è stato eliminato con successo
    </v-snackbar>
    <v-snackbar
        v-model="openSaveSuccessDialog"
        color="success"
    >
      <v-icon dark>
        mdi-chevron-down-circle
      </v-icon>
      Il film è stato Salvato con successo
    </v-snackbar>
    <!--  </v-row>-->
  </div>
</template>

<script>
import moment from "moment";
import db from "../firebaseConfiguration/firebaseInit";

export default {
  data() {
    return {
      loadingModify: false,
      filmToModify: undefined,
      openDialogModify: false,
      cinelandiaFilter: false,
      todayFilter: true,
      loadTable: false,
      nameFilter: "",
      films: [],
      today: "today",
      todayDate: new Date(),
      moment: moment,
      openDeleteFilmDialog: false,
      filmToRemove: {},
      openSuccessDialog: false,
      openSaveSuccessDialog: false,
      search: "",
      headers: [{
        text: 'image',
        align: 'start',
        sortable: false,
        value: 'image',
        filterable: false,
      },
        {text: 'Titolo', value: 'title', sortable: true, align: true},
        {text: 'Data Inizio', value: 'startDate', filterable: false},
        {text: 'Data Fine', value: 'endDate', filterable: false},
        {text: 'Cinelandia', value: 'cinelandia', filterable: false},
        {text: 'In programmazione Oggi', value: 'today', filterable: false},
        {text: 'Modifica', value: 'modify', filterable: false},
      ]
    };
  },
  created() {
    this.loadFilms();
  },
  computed: {
    filterFilms() {
      return this.films.filter(a => a.cinelandia === this.cinelandiaFilter);
    }
  },
  watch: {
    todayFilter() {
      this.loadFilms();
    }
  },
  methods: {
    modifyFilmDialog(film) {
      this.filmToModify = film;
      this.openDialogModify = true
    },
    removeFilmDialog(film) {
      this.filmToRemove = film;
      this.openDeleteFilmDialog = true;
    },
    loadFilms() {
      this.loadTable = true;
      this.films = [];
      let query = db.firestore().collection("film");
      if (this.todayFilter) {
        query = query.where("startDate", ">=", this.getTodayFormatted());
      }
      //db.firestore().collection("film").where("startDate", ">=", this.getTodayFormatted())
      query.get().then(list =>
          list.forEach(a => {
            this.films.push({
              backgroundImage: a.data().backgroundImage,
              id: a.id,
              dates: a.data().dates,
              description: a.data().description,
              endDate: a.data().endDate,
              image: a.data().image,
              startDate: a.data().startDate,
              title: a.data().title,
              type: a.data().type,
              show: false,
              cinelandia: a.data().cinelandia,
              today: a.data().dates.find(a => moment(a.day.toDate()).format('M/D/YYYY') === moment(new Date()).format('M/D/YYYY')) !== undefined,
            });
          })).finally(() => {
               this.loadTable = false
      });
    },
    getDay(element) {
      switch (element) {
        case 0:
          return "Domenica";
        case 1:
          return "Lunedì";
        case 2:
          return "Martedì";
        case 3:
          return "Mercoledì";
        case 4:
          return "Giovedì";
        case 5:
          return "Venerdì";
        case 6:
          return "Sabato";

        default:
          break;
      }
    },
    cardToday(today) {
      return today ? 'cardToday' : 'card';
    },
    removeFilm(idFilm) {
      db.firestore().collection("film").doc(idFilm).delete().then(a => {
        this.openSuccessDialog = true
        this.loadFilms();
      });
      this.openDeleteFilmDialog = false;
    },
    saveModifyFilm(film) {
      this.loadingModify = true;
      db.firestore().collection("film").doc(film.id).set(film, {merge: true}).then(_ => {
        this.openSaveSuccessDialog = true;
        this.openDialogModify = false;
        this.filmToModify = undefined;
        this.loadingModify = false;
      });
    },
    openRemoveDialog(film) {
      this.filmToRemove = film;
      this.openDeleteFilmDialog = true;

    },
    getTodayFormatted() {
      let date = new Date().getFullYear();
      date = date + "-";
      if (new Date().getMonth() + 1 < 10) {
        date = date + "0";
      }
      date = date + new Date().getMonth() + 1;
      date = date + "-";
      if (new Date().getDate() < 10) {
        date = date + "0";
      }
      date = date + new Date().getDate();
      return date;
    }
  }
}
</script>

<style>
.today {
  border-color: green;
  border-width: 2px;
  border-style: solid;
  border-radius: 30px;
}

.cardToday {
  border-color: yellow;
}

.card {
}
</style>
